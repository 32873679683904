'use strict';

import $ from 'jquery'
// import Cookies from 'js-cookie'

var $document = $(document);
const defaults = {
  selector: '.js-msg-close'
}

export default class {
  constructor(options){
    this._onClick = this._onClick.bind(this);

    this._defaults = defaults;
    this._options = options;
    this.settings = Object.assign({}, this._defaults, options);

    this.enabled = false;
    this.init();
  }
  init(){
    this.enable();
  }
  destroy() {

  }
  reInit() {
    this.destroy();
    this.init();
  }
  enable(){
    if(this.enabled) return;
    $document.on('click', this.settings.selector, this._onClick);
    this.enabled = true;
  }
  disable(){
    if(!this.enabled) return;
    $document.off('click', this.settings.selector, this._onClick);
    this.enabled = false;
  }
  _onClick(e){
    var target = $(e.currentTarget).data('close-target');
    var $target = $(target);
    if($target.length) {
      $target.remove();
    }
  }
}
