'use strict';

import $ from 'jquery'
import {assignIn} from 'lodash';

//  Tab
// ================================================
// var TweenLite = require('gsap/src/uncompressed/TweenLite.js');

export default class {
  constructor(options){
    this.onClick = this.onClick.bind(this);

    this._defaults = {
      selector: '.js-tab',
      tabContainerSelector: '.c-tab-container',
    };
    this.settings = assignIn({}, this._defaults, options);

    this.$triggers = $(this.settings.selector);
    this.$tabContainers = $(this.settings.tabContainerSelector);

    this.binded = false;
    this.init();
  }
  init(){
    this.$triggers.on('click', this.onClick);
    this.bind();
  }
  bind(){
    if(!this.binded) {
      this.$triggers.on('click', this.onClick);
      this.binded = true;
    }
  }
  unbind(){
    if(this.binded) {
      this.$triggers.off('click', this.onClick);
      this.binded = false;
    }
  }
  onClick(e){
    e.preventDefault();
    var $this = $(e.currentTarget).addClass('is-selected');
    this.$triggers.not($this).removeClass('is-selected');

    var target = e.currentTarget.hash ? e.currentTarget.hash : ($this.attr('data-tab-target') ? $this.attr('data-tab-target') : '');
    var $select = this.$tabContainers.filter('[data-tab-id="'+ this._removeHash(target) +'"]').addClass('is-selected');
    // $select.trigger('tabSelected');
    this.$tabContainers.not($select).removeClass('is-selected');
    // TweenLite.to(window, 1, {scrollTo:'#member-nav', ease:Power3.easeOut});
    setTimeout(() => {
      $(window).trigger('resize');
    },0);
  }
  // private
  _removeHash(val){
    return val.replace(/#/g, '');
  }
}
