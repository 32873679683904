'use strict';

import $ from 'jquery'

const defaults = {
  'autoEnable': true,
  'hoverSelector': '.js-hover',
  'hoverClass': 'is-hovered',
}
const $document = $(document);

export default class {
  constructor(options){
    this._onTouchstart = this._onTouchstart.bind(this);
    this._onTouchmove = this._onTouchmove.bind(this);
    this._onTouchend = this._onTouchend.bind(this);
    this._onTouchcancel = this._onTouchcancel.bind(this);
    this._onMouseenter = this._onMouseenter.bind(this);
    this._onMouseleave = this._onMouseleave.bind(this);

    this._defaults = defaults;
    this._options = options;
    this.settings = Object.assign({}, this._defaults, options);

    this.enabled = false;
    this.init();
  }
  init(){
    if(this.settings.autoEnable){
      this.enable();
    }
  }
  enable(){
    if(this.enabled) return;
    $document.on('touchcancel', this._onTouchcancel);
    $document.on('touchstart', this.settings.hoverSelector, this._onTouchstart);
    $document.on('touchmove', this.settings.hoverSelector, this._onTouchmove);
    $document.on('touchend', this.settings.hoverSelector, this._onTouchend);
    $document.on('mouseenter', this.settings.hoverSelector, this._onMouseenter);
    $document.on('mouseleave', this.settings.hoverSelector, this._onMouseleave);
    this.enabled = true;
  }
  disable(){
    if(!this.enabled) return;
    $document.off('touchcancel', this._onTouchcancel);
    $document.off('touchstart', this.settings.hoverSelector, this._onTouchstart);
    $document.off('touchmove', this.settings.hoverSelector, this._onTouchmove);
    $document.off('touchend', this.settings.hoverSelector, this._onTouchend);
    $document.off('mouseenter', this.settings.hoverSelector, this._onMouseenter);
    $document.off('mouseleave', this.settings.hoverSelector, this._onMouseleave);
    this.enabled = false;
  }

  // private
  _onTouchstart(e){
    $(e.currentTarget).addClass(this.settings.hoverClass);
    this.isTouched = true;
    setTimeout(() => {
      this.isTouched = false;
    }, 300);
  }
  _onTouchmove(e){
    $(e.currentTarget).removeClass(this.settings.hoverClass);
  }
  _onTouchend(e){
    $(e.currentTarget).removeClass(this.settings.hoverClass);
  }
  _onTouchcancel(){
    $(this.settings.hoverSelector).removeClass(this.settings.hoverClass);
  }
  _onMouseenter(e){
    if(!this.isTouched) {
      $(e.currentTarget).addClass(this.settings.hoverClass);
    }
  }
  _onMouseleave(e){
    if(!this.isTouched) {
      $(e.currentTarget).removeClass(this.settings.hoverClass);
    }
  }
}
