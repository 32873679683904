'use strict';

import clamp from 'clamp-js';
import {debounce} from 'lodash';
import EventEmitter from 'eventemitter2';
// import TooltipJs from 'tooltip.js';

class Clamp {
  constructor(){
    this._onResize = debounce(this._onResize.bind(this), 500);
    this.$targets = null;
    this.clamps = [];
    this.emitter = new EventEmitter();
    this.init();
  }
  init(){
    document.addEventListener('DOMContentLoaded', () => {
      this.$targets = document.querySelectorAll('.js-clamp');
      [].forEach.call(this.$targets, (el) => {
        let line = el.dataset.clamp ? el.dataset.clamp : 1;
        if(line === 1) {
          el.style.overflow = 'hidden';
          el.style.textOverflow = 'ellipsis';
          el.style.whiteSpace = 'nowrap';
        } else {
          var c = clamp(el, {clamp: line ? line : 1, useNativeClamp: false});
          c.line = line;
          c.element = el;
          this.clamps.push(c);
        }
      });
    });
    window.addEventListener('resize', this._onResize);
    this.emitter.emit('init');
  }
  update(){
    this.clamps.forEach((c)=>{
      c.element.innerHTML = c.original;
      clamp(c.element, {clamp: c.line, useNativeClamp: false});
    });
    this.emitter.emit('update');
  }
  _onResize(){
    this.update();
  }
}

let instance = new Clamp();
global.Clamp = instance;
export default instance;