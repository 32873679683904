'use strict';

import 'classlist.js';
import 'matchmedia-polyfill/matchMedia.js';
import 'matchmedia-polyfill/matchMedia.addListener.js';
import 'picturefill/dist/picturefill.min.js';
import './lib/polyfills/requestAnimationFrame';
import './lib/polyfills/performance.now';
import './lib/polyfills/object.assign';
import './lib/polyfills/position-sticky';

import './lib/modernizr/modernizr.build.js';
import bowser from 'bowser';
if(bowser.safari && bowser.version <= 10){
  document.documentElement.classList.add('safari10');
}
if(bowser.msie){
  document.documentElement.classList.add('ie');
}
if(bowser.msedge){
  document.documentElement.classList.add('edge');
}
if(bowser.windows){
  document.documentElement.classList.add('windows');
}
if(bowser.mac){
  document.documentElement.classList.add('mac');
}
global.bowser = bowser;
