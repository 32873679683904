'use strict';

$(function(){

  $('.js-share').on('click', function(event){
    event.preventDefault();
    window.open(this.href, 'sharewindow', 'width=550, height=450, personalbar=0, toolbar=0, scrollbars=1, resizable=1');
    return false;
  });

});