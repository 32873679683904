'use strict';

import TooltipJs from 'tooltip.js';

class Tooltip {
  constructor(){
    this.$targets = null;
    this.init();
  }
  init(){
    document.addEventListener('DOMContentLoaded', () => {
      this.$targets = document.querySelectorAll('.js-tooltip');
      [].forEach.call(this.$targets, (el) => {
        var title = el.dataset.tooltipTitle;
        var placement = el.dataset.tooltipPlacement;
        new TooltipJs(el, {
            placement: placement ? placement : 'bottom-end', // or bottom, left, right, and variations
            template: '<div class="tooltip" role="tooltip"><div class="tooltip__arrow"></div><div class="tooltip__inner"></div></div>',
            title: title ? title : '',
        });
      });
    });
  }
}

let instance = new Tooltip();
global.Tooltip = instance;
export default instance;