// Use stickyfill
// Polyfill for CSS `position: sticky`
// https://github.com/wilddeer/stickyfill
//
//
// .u-sticky {
//   position: sticky;
//   top: 0;
//   &:before,
//   &:after {
//     content: '';
//     display: table;
//   }
// }


import Stickyfill from 'stickyfilljs';
Stickyfill.add(document.querySelectorAll('.js-sticky'));