'use strict';

// Issue: https://github.com/nolimits4web/Swiper/issues/2206
import Swiper from 'swiper/dist/js/swiper.min.js';

document.addEventListener('DOMContentLoaded', () => {

  var swipers = [];

  swipers.push(new Swiper('.js-swiper-container--event', {
    // centeredSlides: true,
    slidesPerView: 3,
    slidesPerGroup: 3,
    speed: 400,
    spaceBetween: 40,
    autoHeight: true,
    // Navigation arrows
    navigation: {
      nextEl: '.js-swiper-button-next--event',
      prevEl: '.js-swiper-button-prev--event',
    },
    breakpoints: {
      767: {
        centeredSlides: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 32,
      },
    }
  }));

  swipers.push(new Swiper('.js-swiper-container--case', {
    centeredSlides: true,
    slidesPerView: 1,
    slidesPerGroup: 1,
    speed: 400,
    spaceBetween: 100,
    touchEventsTarget: 'wrapper',
    // Navigation arrows
    pagination: {
      el: '.js-swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-button-next--case',
      prevEl: '.js-swiper-button-prev--case',
    },
    breakpoints: {
      767: {
        centeredSlides: true,
        spaceBetween: 32,
      },
    },
  }));

  global.Clamp.emitter.on('init', ()=>{
    swipers.forEach((swiper)=>{
      if(swiper.$el) {
        swiper.update();
      }
    })
  })

  global.Clamp.emitter.on('update', ()=>{
    swipers.forEach((swiper)=>{
      if(swiper.$el) {
        swiper.update();
      }
    })
  })

});