'use strict';

(function(){
  document.addEventListener('DOMContentLoaded', function () {

    var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.js-navbar__burger'), 0);

    if ($navbarBurgers.length > 0) {

      $navbarBurgers.forEach(function ($el) {
        $el.addEventListener('click', function () {

          var target = $el.dataset.target;
          var $target = document.getElementById(target);

          $el.classList.toggle('is-active');
          $target.classList.toggle('is-active');

        });
      });
    }

    var mql = window.matchMedia( "(min-width: 960px)" );
    mql.addListener(()=>{
      $navbarBurgers.forEach(function ($el) {
        var target = $el.dataset.target;
        var $target = document.getElementById(target);
        $el.classList.remove('is-active');
        $target.classList.remove('is-active');
      });
    })

  });
})();