'use strict';

import $ from 'jquery'
import jump from 'jump.js'

const $document = $(document);
const defaults = {
  selector: '.js-scroll',
  hash: false,
  duration: 500,
  offset: 0,
  callback: undefined,
  a11y: false,
}

function _removeHash(val){
  return val.replace(/#/g, '');
}

export default class {
  constructor(options){
    this._onClick = this._onClick.bind(this);

    this._defaults = defaults;
    this._options = options;
    this.settings = Object.assign({}, this._defaults, options);

    this.enabled = false;
    this.init();
  }
  init(){
    this.enable();
    if (location.hash) {
      this.scroll(location.hash);
    }
  }
  destroy() {
    this.disable();
  }
  reInit() {
    this.destroy();
    this.init();
  }
  enable(){
    if(this.enabled) return;
    $document.on('click', this.settings.selector, this._onClick);
    this.enabled = true;
  }
  disable(){
    if(!this.enabled) return;
    $document.off('click', this.settings.selector, this._onClick);
    this.enabled = false;
  }
  scroll(target){
    var selector = target;
    var $target = $(target);
    if(!$target.length && target !== '') {
      selector = '[data-scroll-id="' + _removeHash(target) + '"]';
      $target = $(selector);
    }

    if($target.length) {
      jump($target[0], {
        duration: this.settings.duration,
        offset: this.settings.offset,
        callback: this.settings.callback,
        a11y: this.settings.a11y,
      });
    } else if (target === '') {
      jump('body', {
        duration: this.settings.duration,
        offset: this.settings.offset,
        callback: this.settings.callback,
        a11y: this.settings.a11y,
      });
    }
  }
  _onClick(e){
    if(this.settings.hash) {
      e.preventDefault();
    }
    var $this = $(e.currentTarget);
    var target = $this[0].hash ? $this[0].hash : ($this.attr('data-scroll-target') ? $this.attr('data-scroll-target') : '');
    this.scroll(target);
  }
}
