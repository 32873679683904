'use strict';

import './lib/Menu';
import './lib/Carousel';
import './lib/Clamp.js';
import './lib/Tooltip.js';
import GoogleMap from './lib/GoogleMap';
import Tab from './lib/Tab';
import Hover from './lib/Hover';
// import GalleryFlow from './lib/GalleryFlow';
import Message from './lib/Message';
import PageScroll from './lib/PageScroll';
import WebFontLoader from 'webfontloader';
import './lib/ShareButton.js';

WebFontLoader.load({
  custom: {
    families: ['CaveatRegular', 'Material Icons'],
  },
});

document.addEventListener('DOMContentLoaded', () => {
  var gMap = new GoogleMap();
  gMap.add('.c-gmap', {
    latlng: {
      lat: 34.398453,
      lng: 133.083841,
    },
  });
  gMap.init();
  new Tab();
  new Hover({
    hoverSelector: 'a, button, .js-hover, .swiper-pagination-bullet',
  });
  // new GalleryFlow($('.js-gf').get(0));
  new Message();
  new PageScroll();
});
