/*globals google */

// GoogleMap
'use strict';
import {debounce} from 'lodash';

const defaults = {
  latLng: {
    // AC MIHARA
    lat: 34.398453,
    lng: 133.083841,
  },
  styles: [
    // {
    //   'stylers': [
    //     { 'saturation': -100 }
    //   ]
    // }
  ],
  marker: {
    url: null,
    width: null,
    height: null,
  },
};

class GoogleMap {
  constructor(element, options) {
    this._options = options;
    this._defaults = defaults;
    this.settings = Object.assign({}, this._defaults, options);

    this.element = element;
    this.map = null;
    this.marker = null;
  }
  init() {
    if (typeof this.element === 'undefined') return;

    this.map = new google.maps.Map(this.element, {
      zoom: 16,
      center: this.settings.latLng,
      mapTypeControl: false,
      panControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: this.settings.styles,
    });

    if (this.element.dataset.markerUrl) {
      this.marker = new google.maps.Marker({
        position: this.settings.latLng,
        map: this.map,
        icon: {
          url: this.element.dataset.markerUrl,
          scaledSize: new google.maps.Size(
            this.element.dataset.markerWidth,
            this.element.dataset.markerHeight,
          ),
        },
      });
    } else if (this.settings.marker.url) {
      this.marker = new google.maps.Marker({
        position: this.settings.latLng,
        map: this.map,
        icon: {
          url: this.settings.marker.url,
          scaledSize: new google.maps.Size(
            this.settings.marker.width,
            this.settings.marker.height,
          ),
        },
      });
    } else {
      this.marker = new google.maps.Marker({
        position: this.settings.latLng,
        map: this.map,
      });
    }
  }
}

export default class {
  constructor(options) {
    this.onResize = debounce(this.onResize.bind(this), 200);

    this._options = options;
    this._defaults = defaults;
    this.settings = Object.assign({}, this._defaults, options);

    // アクセス出来ない時は Map を表示しない
    if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
      // console.warn('Google API is not found.');
      return;
    }

    this.maps = [];
    this.binded = false;
  }

  init() {
    google.maps.event.addDomListener(window, 'load', () => {
      this.initMaps();
    });
    this.bind();
  }

  destroy() {
    this.maps = [];
    this.unbind();
  }

  reinit() {
    this.initMaps();
    this.bind();
  }

  bind() {
    if (this.binded) return;
    window.addEventListener('resize', this.onResize);
  }

  unbind() {
    if (!this.binded) return;
    window.removeEventListener('resize', this.onResize);
  }

  add(element, options) {
    var elements = Array.prototype.slice.call(
      document.querySelectorAll(element),
      0,
    );
    if (elements.length) {
      elements.forEach(el => {
        this.maps.push(new GoogleMap(el, options));
      });
    }
  }

  initMaps() {
    for (var i = 0; i < this.maps.length; i++) {
      this.maps[i].init();
    }
  }

  onResize() {
    var n = this.maps.length;
    if (n > 0) {
      for (var i = 0; i < n; i++) {
        let gmap = this.maps[i];
        if (typeof gmap.map !== 'undefined') {
          google.maps.event.trigger(gmap.map, 'resize');
          gmap.map.setCenter(gmap.settings.latLng);
        }
      }
    }
  }
}
